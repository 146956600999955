import Constants from "../Constants";
import "../SCSS/DisplayErrorComponent.scss";
import CancelIcon from '@mui/icons-material/Cancel';

const TaskNotStartedPage: React.FC = () => {
  return (
    <div className="error-main-container">
      <div className="error-box">
        <div className="error-icon">
          <CancelIcon />
        </div>
        <p>{Constants.TasKNotStarted}</p>
      </div>
    </div>
  );
};
export default TaskNotStartedPage;