import React, { createContext, useContext, useState, useMemo } from 'react';

const LoadingContext = createContext<{ isLoading: boolean; setLoading: (loading: boolean) => void } | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);

  const value = useMemo(() => ({ isLoading, setLoading }), [isLoading, setLoading]);

  return (
    <LoadingContext.Provider value={value}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
