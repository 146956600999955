const Constants = {
    SubmittedText: "Thanks for providing the information.",
    AlreadySubmittedText: "You have already provided the required information.",
    StonexHeader: "StoneX",
    WavingHand: "👋",
    Instructions: "Hi {0}!",
    AcceptRejectText: "Please accept or reject this application",
    ErrorText: "It looks like something went wrong. Please try to re-load this page to correct the issue",
    UnAuthorizedText: "You are not authorized to view this page.",
    TasKNotStarted: "Task is not ready for approval.",
    AcknowledgementText: "By attesting, I am representing that I am authorized and empowered to complete the below task.",
};

export default Constants;