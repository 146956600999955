import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import '../SCSS/EmailApprovalComponent.scss';
import SuccessPage from './SuccessPage';
import DisplayError from './DisplayError';
import EmailApprovalApiServiceClient from '../Clients/EmailApprovalApiServiceClient';
import { ProductDetails, SubmitDataRequest, ProductDetail } from '../types/AdminTypes';
import { scrollToTop } from './utils/ScrollUtil';
import Loader from './Loader';
import { Card, CardContent, Collapse, IconButton, Typography } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import Constants from '../Constants';

const EmailApprovalComponent: FunctionComponent = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [isAcknowledged, setAcknowledged] = useState<boolean>(false);
  const [submitDataReq, setSubmitDataRequest] = useState<SubmitDataRequest>({
    entityId: '',
    approverUserId: '',
    approverEmail: '',
    approvalStatus: '',
    entityDraftId: '',
    taskName: '',
    isAcknowledged: false,
    journeyId: '',
    taskId:'',
    comments:''
  });
  const [ProductDetailsData, setProductDetailsData] = useState<ProductDetails>({
    productDetails: [],
    customerInformation: {
      entityName: '',
      intlCompany: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      oms: ''
    },
    knowYourCustomer: {
      descriptionOfCustomerBusiness: '',
      descriptionOfDeal: '',
      facilityProposed: ''
    },
    financials: {
      netWorth: '',
      assets: '',
      revenue: '',
      profit: ''
    },
    productCount: 0
  });
  const [loading, setLoading] = useState<boolean>(false);
  const token = sessionStorage.getItem('customToken');

  const ProductInformation = ({ product }: { product: ProductDetails }) => {
    const [openIndexes, setOpenIndexes] = useState<boolean[]>(Array(product.productCount).fill(false));
  
    const handleToggle = (index: number) => {
      const newOpenIndexes = [...openIndexes];
      newOpenIndexes[index] = !newOpenIndexes[index];
      setOpenIndexes(newOpenIndexes);
    };
  
    if (!product || !product.productDetails || !Array.isArray(product.productDetails)) {
      return <Typography variant="body2">No products available</Typography>;
    }
  
    return (
      <div className="product-information">
        <span className='product-details-heading'> <AssignmentOutlinedIcon className="product-icon"/> Products </span><br/>
        {product.productCount > 0 ? (
          <div className="product-details-grid">
            {product.productDetails.map((detail, index) => (
              <Card elevation={5} key={detail.ProductType} className="product-card">
                <CardContent>
                  <Typography variant="h6" component="div" className="product-title">
                     {detail.ProductType || ""}
                  </Typography>
                  <Typography variant="body2" component="div">
                    Stonex Booking Entity: <b className='product-field-values'>{detail.StonexBookingEntity || ""}</b><br />
                    Product Family: <b className='product-field-values'> {detail.ProductFamily || ""}</b>
                  </Typography>
                  <IconButton onClick={() => handleToggle(index)} aria-label="toggle details" className="collapse-icon">
                       {openIndexes[index] ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                  </IconButton>
                </CardContent>
                <Collapse in={openIndexes[index]} timeout="auto" unmountOnExit>
                  <CardContent sx={{ padding: '8px 16px' }}>
                    <Typography variant="body2" sx={{ marginBottom: '4px' }}>
                       Sub Product: <b className='product-field-values'>{detail.SubProduct || ""}</b><br />
                       Service: <b className='product-field-values'>{detail.Service || ""}</b>
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            ))}
            {product.productCount > 3 && <p className="more-text">There are more products added, to view all products please check customer details</p>}
          </div>
        ) : (
          <Typography variant="body2">No products available</Typography>
        )}
      </div>
      
    );
  };

const CustomerInformation = ({ customer }: { customer: ProductDetails['customerInformation'] }) => {
  const addressParts = [
    customer.address1,
    customer.address2,
    customer.city,
    customer.state,
    customer.country,
    customer.postalCode,
  ].filter(part => part);
  
  const fullAddress = addressParts.join(', ');

  return (
    <div className="customer-info-container">
     <h3 className="customer-heading">
      <PersonOutlineOutlinedIcon className="person-icon" />
      Customer
    </h3>
      <Card elevation= {5} className="customer-card">
        <CardContent>
          <Typography variant="h5" component="div" className="customer-title">
            {customer.entityName || 'No Entity Name'}
          </Typography>
          <Typography variant="body2" className="customer-address">
            {fullAddress || 'No Address Provided'}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedIdToken = sessionStorage.getItem('idToken');
        if (!storedIdToken) {
          setError(true);
          throw new Error('Token not found');
        }
          const parsedIdToken = JSON.parse(storedIdToken);
          const email = parsedIdToken.claims?.email;
          const name = parsedIdToken.claims?.name;
          const cdsId = parsedIdToken.claims?.cds_id;

        if (!email){
          setError(true);
          throw new Error('Email not found');
        } 
        setName(name);

        if (!token){
          setError(true);
          throw new Error('Token not found');
        }       
        const parsedToken = JSON.parse(atob(token.split('.')[1]));
        setData(parsedToken,cdsId,email);
      } catch {
        setError(true);
      }
    };
    fetchUserData();
  }, [token]);

  const setData=(parsedToken: any, cdsId: string, email:string)=>{
    setSubmitData(parsedToken,cdsId,email);
    
    const productDetailsString = parsedToken?.productDetails || "";
    let parsedProductDetails: ProductDetail[] = [];
    if(productDetailsString!==undefined && productDetailsString!==null && productDetailsString!==""){
      parsedProductDetails = JSON.parse(productDetailsString)
    }

    setProductDetailsData({
      productDetails: parsedProductDetails,
      customerInformation: setCustomerInformation(parsedToken),
      knowYourCustomer: {
        descriptionOfCustomerBusiness: parsedToken?.descriptionOfCustomerBusiness || '',
        descriptionOfDeal: parsedToken?.descriptionOfDeal || '',
        facilityProposed: parsedToken?.facilityProposed || ''
      },
      financials: {
        netWorth: parsedToken?.netWorth || '',
        assets: parsedToken?.assets || '',
        revenue: parsedToken?.revenue || '',
        profit: parsedToken?.profit || ''
      },
      productCount: parsedToken?.productCount || 0
    });
  }

  const setCustomerInformation=(parsedToken:any)=>{
    let customerInformation = {
      entityName: parsedToken?.entityName || '',
      intlCompany: parsedToken?.intlCompany || '',
      address1: parsedToken?.address1 || '',
      address2: parsedToken?.address2 || '',
      city: parsedToken?.city || '',
      state: parsedToken?.state || '',
      country: parsedToken?.country || '',
      postalCode: parsedToken?.postalCode || '',
      oms: parsedToken?.oms || ''
    } 
    return customerInformation;
  }

  const setSubmitData=(parsedToken:any, cdsId: string, email:string)=>{
    setSubmitDataRequest({
      entityId: parsedToken?.entityId || '',
      approverUserId: cdsId,
      approverEmail: email,
      approvalStatus: '',
      isAcknowledged: false,
      entityDraftId: parsedToken?.entityDraftId || '',
      taskName: parsedToken?.taskName || '',
      journeyId: parsedToken?.journeyId || '',
      taskId: parsedToken?.taskId || '',
      comments: comments
    });
  }

  const postDataToEmailAPI = async (approvalStatus: string) => {
    setLoading(true);
    submitDataReq.comments = comments;

    const data = { ...submitDataReq, approvalStatus, isAcknowledged: isAcknowledged };
    try {
      const response = await EmailApprovalApiServiceClient.SubmitData(data);
      if (response.toString().includes('Error')) {
        setError(true);
        throw new Error("API response contains 'Error'");
      }
      scrollToTop();
      return true;
    } catch {
      setError(true);
      scrollToTop();
      return false;
    }
    finally {
      setLoading(false); 
    }
  };

  const handleApprove = () => {
    postDataToEmailAPI('Approved').then(() => setFormSubmitted(true)).catch(() => setError(true));
  };

  const handleReject = () => {
    postDataToEmailAPI('Rejected').then(() => setFormSubmitted(true)).catch(() => setError(true));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAcknowledged(e.target.checked);
  };

  return (
    <Fragment>
      {loading && <Loader />}
      {!error && !formSubmitted && (
        <div className="container">
          <div className="content">
          <h2>{Constants.WavingHand}</h2>
            <h2 className="heading">{Constants.Instructions.replace('{0}', name)}</h2>
            <div className="text">{Constants.AcceptRejectText}</div>
            <CustomerInformation customer={ProductDetailsData.customerInformation} />
            <ProductInformation product={ProductDetailsData} />
            <div>
               <label htmlFor="comments">Comments:</label>
               <br />
                <textarea
                  id="comments"
                  className="comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
            </div>
            <div className="Checkbox-wrapper">
              <input
                type="checkbox"
                disabled={loading}
                checked={isAcknowledged}
                onChange={handleChange}
                id="Acknowledgement"
                className="Checkbox"
              />
              <label htmlFor="Acknowledgement" className="CheckboxLabel">
                {Constants.AcknowledgementText}
              </label>
              <br />
              <br />
            </div>
            <div className="buttons">
              <button className="button button-approve" disabled={!isAcknowledged || loading} onClick={handleApprove}>Approve</button>
              <button className="button button-reject" disabled={!isAcknowledged || loading} onClick={handleReject}>Reject</button>
            </div>
          </div>
        </div>
      )}
      {!error && formSubmitted && <SuccessPage />}
      {error && <DisplayError />}
    </Fragment>
  );
};

export default EmailApprovalComponent;