const oktaConfig = {
  clientId: (window.env && window.env.REACT_APP_OKTA_CLIENT_ID) 
    || process.env.REACT_APP_OKTA_CLIENT_ID 
    || "0oaid43oxyA7PFTqk1d7",

  issuer: (window.env && window.env.REACT_APP_OKTA_ISSUER) 
    || process.env.REACT_APP_OKTA_ISSUER 
    || "https://id.preview.stonex.com/oauth2/default",

  scopes: ['openid', 'profile', 'email'],
  
  redirectUri: window.origin + ((window.env && window.env.REACT_APP_OKTA_REDIRECTURI) 
    || process.env.REACT_APP_OKTA_REDIRECTURI 
    || "/authorization-code/callback"),
  
  pkce: true,
  
  tokenManager: {
    storage: 'sessionStorage',
  },
};

export default oktaConfig;
