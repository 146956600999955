import Constants from "../Constants";
import "../SCSS/SuccessPage.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessPage: React.FC = () => {
  return (
    <div className="success-main-container">
      <div className="success-box">
        <div className="ellipse" ><CheckCircleIcon key="ellipse" /></div>
        <p> {Constants.SubmittedText} </p></div>
    </div>
  );
};
export default SuccessPage;