import Layout from './Components/Layout';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Callback from './Components/Callback';
import { LoadingProvider } from './Components/LoadingContext';

function App() {
  return (
    <LoadingProvider>
      <div className="App">
        <Routes>
          <Route path="/authorization-code/callback" element={<Callback />} />
          <Route path="/" element={<Layout />} />
        </Routes>
      </div>
    </LoadingProvider>
  );
}

export default App;
