import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLoading } from './LoadingContext';

const Callback: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const { setLoading } = useLoading();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const tokensResponse = await oktaAuth.token.parseFromUrl();
        const { tokens } = tokensResponse;

        if (tokens?.accessToken && tokens?.idToken) {
          setIsAuthenticated(true);
          sessionStorage.setItem('isAuthenticated', 'true');
          sessionStorage.setItem('idToken', JSON.stringify(tokens?.idToken));
        } else {
          console.error('No valid tokens received.');
        }
      } catch (error) {
        console.error('Error during token parsing:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [oktaAuth, setLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      window.location.replace('/');
    }
  }, [isAuthenticated]);

  return null;
};

export default Callback;
