import Constants from "../Constants";
import "../SCSS/AlreadySubmittedPage.scss";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AlreadySubmittedPage: React.FC = () => {
  return (
    <div className="submitted-main-container">
      <div className="submitted-box">
        <div className="ellipse" ><CheckCircleIcon key="ellipse" /></div>
        <p> {Constants.AlreadySubmittedText} </p></div>
    </div>
  );
};
export default AlreadySubmittedPage;