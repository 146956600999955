export const scrollToTop = () => {
  const classNames = [
    'Widget_WidgetContentContainer',
    'Workspace_SelectedWorkspace',
    "Workspace_SelectedWorkspace__3B1WU",
    'SelectedWorkspace'
  ];

  for (const className of classNames) {
    const elements = document.querySelectorAll<HTMLElement>(`[class*="${className}"]`);

    if (elements.length > 0) {
      const element = elements[0];
      element.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        element.scrollTop = 0;
      }, 500);
      break;
    }
  }
};