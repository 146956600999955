import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import EmailApprovalComponent from './EmailApprovalComponent';
import DisplayError from './DisplayError';
import AlreadySubmittedPage from './AlreadySubmittedPage';
import UnAuthorizedPage from './UnAuthorizedPage';
import TaskNotStartedPage from './TaskNotStartedPage';
import { TaskStatus } from './Enums/TaskStatus';
import Loader from './Loader';
import { useOktaAuth } from '@okta/okta-react';
import { useLoading } from './LoadingContext';
import EmailApprovalApiServiceClient from '../Clients/EmailApprovalApiServiceClient';
import { EmailStatus } from '../types/AdminTypes';

const Layout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { isLoading, setLoading } = useLoading();
  const [error, setError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [taskNotStarted, setTaskNotStarted] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isAuthorized, setAuthorized] = useState<boolean>(true);
  const { oktaAuth, authState } = useOktaAuth();
  const token = searchParams.get('token');
  const hasFetchedStatus = useRef(false);

  useEffect(() => {
    if (token && !sessionStorage.getItem('customToken')) {
      sessionStorage.setItem('customToken', token);
    }
  }, [token]);

  useEffect(() => {
    const isAuthenticatedFromSession = sessionStorage.getItem('isAuthenticated') === 'true';

    if (!isAuthenticatedFromSession && (!authState || !authState.isAuthenticated)) {
      oktaAuth.signInWithRedirect();
    } else if (!hasFetchedStatus.current) {
      getStatus();
      hasFetchedStatus.current = true;
    }
  }, [authState, oktaAuth]);

  const getStatus = async () => {
    if (loaded) return;

    setLoading(true);
    try {
      const customToken = sessionStorage.getItem('customToken');
      const storedIdToken = sessionStorage.getItem('idToken');
      
      if (!customToken || !storedIdToken) {
        throw new Error('Token not found');
      }
  
      const parsedIdToken = JSON.parse(storedIdToken);
      const parsedToken = parseCustomToken(customToken);
      const email = parsedIdToken.claims?.email;
  
      if (!isAuthorizedEmail(email, parsedToken.email)) {
        setAuthorized(false);
        return;
      }
  
      if (isTokenExpired(parsedToken.exp)) {
        setAuthorized(false);
        return;
      }
  
      const { journeyId, taskId } = parsedToken;
      const response = await fetchEmailStatus(journeyId, taskId);
  
      if (response.isSuccessful !== undefined) {
        handleEmailStatus(response);
      } else {
        setError(true);
      }
    } catch (err) {
      console.error("Error occurred:", err);
      setError(true);
    } finally {
      setLoaded(true);
      setLoading(false);
    }
  };

  const parseCustomToken = (customToken: string) => {
    return JSON.parse(atob(customToken.split('.')[1]));
  };

  const isAuthorizedEmail = (email1: string | undefined, email2: string | undefined) => {
    return email1?.toLowerCase() === email2?.toLowerCase();
  };

  const isTokenExpired = (exp: number | undefined) => {
    const currentTime = Date.now() / 1000;
    return exp !== undefined && currentTime > exp;
  };

  const fetchEmailStatus = async (journeyId: string, taskId: string) => {
    const response = await EmailApprovalApiServiceClient.getStatus(journeyId, taskId);
    
    if (response.toString().includes('Error')) {
      throw new Error("API response contains 'Error'");
    }
  
    return response;
  };

  const handleEmailStatus = (emailStatus: EmailStatus) => {
    if (emailStatus.isSuccessful) {
      if (emailStatus.isResponseSubmitted && emailStatus.taskStatus === TaskStatus.Done) {
        setSubmitted(true);
      } else if (emailStatus.taskStatus === TaskStatus.Not_Started) {
        setTaskNotStarted(true);
      }
    } else {
      setError(true);
    }
  };

  if (isLoading || !loaded) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div>
        {loaded && isAuthorized && !error && !submitted && !taskNotStarted && <EmailApprovalComponent />}
        {loaded && isAuthorized && !error && submitted && <AlreadySubmittedPage />}
        {loaded && isAuthorized && !error && taskNotStarted && <TaskNotStartedPage />}
        {loaded && !isAuthorized && !error && <UnAuthorizedPage />}
        {loaded && error && <DisplayError />}
      </div>
    </Fragment>
  );
};

export default Layout;
