import originalFetch from "isomorphic-fetch";
import { SubmitDataRequest } from "../types/AdminTypes";

const fetchRetry = require("fetch-retry")(originalFetch);

const emailApprovalApi = (window.env && window.env.REACT_APP_HOST_EmailApproval_API) 
    || process.env.REACT_APP_HOST_EmailApproval_API 
    || 'http://localhost:8083'; 

const retryAttempt = (window.env && window.env.REACT_APP_RETRY_MAXATTEMPTS) 
    || process.env.REACT_APP_RETRY_MAXATTEMPTS 
    || 3; 

const retryDelay = (window.env && window.env.REACT_APP_RETRY_MAXDELAYS) 
    || process.env.REACT_APP_RETRY_MAXDELAYS 
    || 500;
    
class EmailApprovalApiServiceClient {
  baseUrl = `${emailApprovalApi}/emailapproval`;

  async SubmitData(obj: SubmitDataRequest): Promise<any> {
    const endpoint = `${this.baseUrl}/taskstatus`;

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };

    try {
      const response = await fetchRetry(endpoint, requestOptions, {
        retries: retryAttempt,
        retryDelay: retryDelay,
      });
      
      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(`Request failed with statusCode ${response.status}: ${errorText}`);
      }
    } catch (error) {
      console.error("SubmitData request failed: ", error);
      throw error;
    }
  }

  async getStatus(journeyId: string, taskId: string): Promise<any> {
    const endpoint = `${this.baseUrl}/status/journey/${journeyId}/task/${taskId}`;

    try {
      const response = await fetch(endpoint, { 
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else {
        const errorText = await response.text();
        throw new Error(`Request failed with statusCode ${response.status}: ${errorText}`);
      }
    } catch (error) {
      console.error("getStatus request failed: ", error);
      throw error;
    }
  }
}

export default new EmailApprovalApiServiceClient();