import "../SCSS/UnAuthorizedPage.scss";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { scrollToTop } from "./utils/ScrollUtil";
import { useEffect } from "react";
import Constants from "../Constants";

const UnAuthorizedPage: React.FC = () => {
  
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="unAuthorized-main-container">
      <div className="unAuthorized-box">
        <div className="lockOutlined" ><LockOutlinedIcon key="lock" /></div>
        <p> {Constants.UnAuthorizedText} </p></div>
    </div>
  );
};
export default UnAuthorizedPage;