import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import oktaConfig from './Components/oktaConfig';

const oktaAuth = new OktaAuth(oktaConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={(oktaAuth, uri) => {
          window.location.replace(uri);
        }}
      >
        <App />
      </Security>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
